var sw = window.innerWidth;
var sh = window.innerHeight;
var header = document.querySelector("header");
var sticky = 0;

// Font Resizer ------------------- Start
function fontResizer() {
  var perc = parseInt(sw) / 120;
  document.body.style.fontSize = perc + "px";
}

// Landscape Mode off ---------------- Start
const mql = window.matchMedia("(orientation:landscape)");
mql.addEventListener("change", (event) => {
  if (event.matches) {
    if (innerWidth < 930) {
      // console.log("Now in landscape orientation");
      document.getElementById("portrait-warning").style.display = "flex";
    }
  } else {
    // console.log("Now in portrait orientation");
    document.getElementById("portrait-warning").style.display = "none";
  }
});

// Loader ------------------- Start
function pageLoader() {
  // document.querySelector("html").classList.add("loadjs");
  setTimeout(function () {
    if (document.querySelector(".loader-first")) {
      document.querySelector(".loader-first").style.display = "none";
      document.querySelector("html").classList.remove("loadjs");
    }
  }, 700);
}

// Sticky Header ---------------- Start
(function () {
  if (document.querySelector(".header")) {
    // Sticky Header Class Add and Remove ---------------- Start
    function stickyHeader() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    }
    // Sticky Header ---------------- End

    // Window On Scroll-----------------Start
    window.addEventListener("scroll", (event) => {
      stickyHeader();
    });
  }
})();

// Window on Load ----------------- Start
window.onload = function () {
  pageLoader();
  if (sw > 1199) {
    fontResizer();
  }
};





// Window On Resize ----------------- Start
(function () {
  window.addEventListener("resize", (event) => {
    sw = window.innerWidth;
    sh = window.innerHeight;
    if (sw > 1025) {
      if (sw < 1400 && sw > 1300 && sh > 900) {
      } else {
        fontResizer();
      }
    }

  });

})();

// SVG Convert Code
window.addEventListener("load", function () {
  (function () {
    if (document.querySelector(".svg-convert")) {
      let svgs = document.querySelectorAll("img.svg-convert");
      svgs.forEach((el) => {
        const imgID = el.getAttribute("id");
        const imgClass = el.getAttribute("class");
        const imgURL = el.getAttribute("src");

        fetch(imgURL)
          .then((response) => response.text())
          .then((data) => {
            const parser = new DOMParser();
            const xmlDoc = parser.parseFromString(data, "text/html");
            let svg = xmlDoc.querySelector("svg");
            if (typeof imgID !== "undefined") {
              svg.setAttribute("id", imgID);
            }

            if (typeof imgClass !== "undefined") {
              svg.setAttribute("class", imgClass + " svg-converted");
            }

            svg.removeAttribute("xmlns:a");

            el.parentNode.replaceChild(svg, el);
          });
      });
    }
  })();
});

// Navigation slide Active on a mobile ---------------- Start
(function () {
  if (document.querySelector("#toggle")) {
    let toggleMenu = document.querySelector("#toggle");

    let htmlEl = document.querySelector("html");
    toggleMenu.addEventListener("click", (event) => {
      // console.log('this', event)
      let _this = event.target;
      _this.classList.toggle("active");
      document.querySelector(".navigation").classList.toggle("open");
      htmlEl.classList.toggle("overflow-hidden");
    });
  }
})();

// navigation accordion ---------------- Start




// animate Observer------------Start
(function () {
  if (window.innerWidth > 767) {
    let anim = [...document.querySelectorAll(".animate")];

    let options = {
      rootMargin: "0px",
      threshold: 0.2,
    };

    let animate = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.animation = `anim 1s ${entry.target.dataset.delay} forwards ease-out`;
        }
      });
    };

    let observer = new IntersectionObserver(animate, options);

    anim.forEach((item, idx) => {
      observer.observe(item);
    });
  }
})();


// Lazy Load Img observer
(function () {
  document.addEventListener("DOMContentLoaded", () => {
    const imgElements = document.querySelectorAll("img[data-src]");

    const lazyLoadingImage = (entries, observer2) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) return;
        entry.target.src = entry.target.dataset.src;
        entry.target.addEventListener("load", () => {
          entry.target.classList.remove("lazy-img");
          observer2.unobserve(entry.target);
        });
      });
    };
    const lazyLoadingObserver = new IntersectionObserver(lazyLoadingImage, {
      threshold: 0,
      rootMargin: "0px 0px 150px 0px",
    });
    imgElements.forEach((img) => lazyLoadingObserver.observe(img));
  });
})();

// clip path observer
(function () {
  if (window.innerWidth > 767) {
    let imageObserver = [...document.querySelectorAll(".img__observer")];
    let option = {
      rootMargin: "-200px",
      threshold: 0.2,
    };

    let setItemActive = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
        }
      });
    };

    let observer2 = new IntersectionObserver(setItemActive, option);

    imageObserver.forEach((item1, idx) => {
      observer2.observe(item1);
    });
  }
})();



document.addEventListener('DOMContentLoaded', function () {
  var path = window.location.href;
  var links = document.querySelectorAll('.header__wrapper li a');
  links.forEach(function (link) {
    if (link.href === path) {
      link.classList.add('active');
    }
  });
});


let toggleFooter = document.querySelectorAll(".toggle-btn");


if (sw < 767) {
  if (toggleFooter.length > 0) {
    for (let i = 0; i < toggleFooter.length; i++) {
      const setActive = (el) => {
        let bol = el.classList.contains('opened');
        [...el.parentElement.parentElement.children].forEach((sib) => {
          sib.children[0].classList.remove("opened");
          let panel = sib.children[1];
          panel.style.maxHeight = null;
          panel.classList.remove("opened")
        });
        if (bol == false) {
          el.classList.add("opened");
          let panel = el.nextElementSibling;
          panel.classList.add("opened")
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
      };
      let spans = [...toggleFooter[i].querySelectorAll(".toggle-btn > h5")];
      spans.forEach((el) => el.addEventListener("click", (e) => setActive(el)));
    }
  }
}
// -------------------- above code will be common for all websites --------------------------



function Modal(modalMainWrapper, backdrop, show) {

  if (document.querySelector('.modal')) {
    let modal = document.querySelector(modalMainWrapper),
      modal_backdrop = document.querySelector(backdrop),
      show_modals = document.querySelectorAll(show),
      rootel = document.querySelector("html");

    show_modals.forEach(function (show_modal) {
      show_modal.addEventListener("click", function (e) {
        e.stopPropagation();
        if (!this.classList.contains("show")) {
          modal.classList.add("show");
          modal_backdrop.classList.add("show");
          this.classList.add("show");
          rootel.classList.add("modal-root-overflow");
        }
        else {
          modal.classList.remove("show");
          modal_backdrop.classList.remove("show");
          this.classList.remove("show");
          rootel.classList.remove("modal-root-overflow");
        }

      });
    });


    document.addEventListener("click", function (e) {
      e.stopPropagation();
      if (e.target.closest(".modal")) {
        // console.log("modal")
        if (e.target.matches(".modal__cancel")) {
          // console.log(e.target.matches(".modal__cancel")  )
          modal.classList.remove("show");
          modal_backdrop.classList.remove("show");
          rootel.classList.remove("modal-root-overflow");
          show_modals.forEach(function (show_modal) {
            show_modal.classList.remove("show");
          });
        }
      }
      else {
        modal.classList.remove("show");
        modal_backdrop.classList.remove("show");
        rootel.classList.remove("modal-root-overflow");
        show_modals.forEach(function (show_modal) {
          show_modal.classList.remove("show");
        });
      }
    });
  }
}

Modal(".modal--search", ".modal-backdrop--search", ".show-modal--search");

// menu nav wrapper
if (document.querySelector(".navigation-wrapper")) {
  let dropdownLink = document.querySelectorAll(".menu__menuitem--dropdown");
  let dropdown = document.querySelectorAll(".dropdown");
  let closeDropdown = document.querySelectorAll(".dropdown__icon-icon");

  dropdownLink.forEach((item, index) => {
    item.addEventListener("click", () => {
      if (item.classList.contains("active")) {
        item.classList.remove("active");
        // document.querySelector("html").classList.remove("overflow-hidden");
        dropdown.forEach(item => item.classList.remove("active"))

      } else {
        dropdownLink.forEach(item => item.classList.remove("active"))
        item.classList.add("active");
        // document.querySelector("html").classList.add("overflow-hidden");
        dropdown.forEach(item => item.classList.remove("active"))
        dropdown[index].classList.add("active");
      }
      if (sw < 1168) {
        dropdown.forEach(item => {
          if (item.classList.contains("active")) {
            if (item.style.maxHeight) {
              item.style.maxHeight = null;
            } else {
              item.style.maxHeight = item.scrollHeight + "px";
            }
          } else {
            item.style.maxHeight = null;
          }
        })
      }
    })
  })

  closeDropdown.forEach((item) => {
    item.addEventListener("click", function (e) {
      e.preventDefault();
      this.closest('.active').classList.remove('active');
      e.stopPropagation();
    });
  });

}

if (document.querySelectorAll('.projects-stats__col [data-count]')) {
  const countValue = document.querySelectorAll('.projects-stats__col [data-count]')

  const easeInOutQuad = (t) => t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t;

  const inViewportCounter = (el) => {

    const duration = +el.dataset.duration || 3000;
    const start = 0;
    const end = parseInt(el.getAttribute('data-count').replace(/,/g, '')) || 100;
    let raf;

    const counterStart = () => {
      if (start === end) return; // If equal values, stop here.

      const range = end - start;
      let curr = start; // Set current to start
      const timeStart = Date.now();

      const loop = () => {
        let elaps = Date.now() - timeStart;
        if (elaps > duration) elaps = duration;
        const frac = easeInOutQuad(elaps / duration); // Get the time fraction with easing
        const step = frac * range; // Calculate the value step
        curr = start + step; // Increment or Decrement current value
        el.textContent = Math.trunc(curr); // Apply to UI as integer
        if (elaps < duration) raf = requestAnimationFrame(loop); // Loop
      };

      raf = requestAnimationFrame(loop); // Start the loop!
    };

    const counterStop = (el) => {
      cancelAnimationFrame(raf);
      el.textContent = start;
    };

    const inViewport = (entries, observer) => {
      entries.forEach(entry => {
        // Enters viewport:
        if (entry.isIntersecting) counterStart(entry.target);
        // Exits viewport:
        else counterStop(entry.target);
      });
    };
    const Obs = new IntersectionObserver(inViewport);
    const obsOptions = {};
    // Attach observer to element:
    Obs.observe(el, obsOptions);
  };

  countValue.forEach(inViewportCounter);
}

(function () {
  if (document.querySelector(".newsBlodgSec")) {

    const filters = document.querySelectorAll('.filter'),
      filterWrapper = document.querySelector('.fliterWrapper'),
      fistFilter = filterWrapper.querySelector('.filter');

    fistFilter.classList.add("active")


    filters.forEach(filter => {


      filter.addEventListener('click', function () {



        let selectedFilter = filter.getAttribute('data-filter');
        let itemsToHide = document.querySelectorAll(`.newsBlogFilteredCardWrapper .newsBlogFilteredCard:not([data-filter='${selectedFilter}'])`);
        let itemsToShow = document.querySelectorAll(`.newsBlogFilteredCardWrapper [data-filter='${selectedFilter}']`);

        filters.forEach(el => {
          el.classList.remove('active');
        });
        this.classList.add('active');

        if (this.classList.contains("filter-2")) {
          this.closest(".fliterWrapper").classList.add("slide-right")
        }

        if (!this.classList.contains("filter-2")) {
          this.closest(".fliterWrapper").classList.remove("slide-right")
        }


        if (selectedFilter == 'all') {
          itemsToHide = [];
          itemsToShow = document.querySelectorAll('.newsBlogFilteredCardWrapper [data-filter]');
        }

        itemsToHide.forEach(el => {
          el.classList.add('hide');
          el.classList.remove('show');
        });

        itemsToShow.forEach(el => {
          el.classList.remove('hide');
          el.classList.add('show');
        });


      });


    });
  }
})();

// accordian
if (document.querySelector(".about-us__accordian-section")) {
  const accordianBtn = document.querySelectorAll(".about-us__accordian-head");
  const accordianContainer = document.querySelectorAll(".about-us__accordian-body");
  accordianContainer.forEach(item => {
    if (item.classList.contains("active")) {
      item.style.maxHeight = item.scrollHeight + "px";
    }
  })
  accordianBtn.forEach((item, index) => {
    item.addEventListener("click", () => {

      if (item.classList.contains("active")) {
        item.classList.remove("active")
        accordianContainer.forEach(item => item.classList.remove("active"))
      } else {
        accordianBtn.forEach(item => item.classList.remove("active"))
        item.classList.add("active")

        accordianContainer.forEach(item => item.classList.remove("active"))
        accordianContainer[index].classList.add("active")
      }

      accordianContainer.forEach(item => {
        if (item.classList.contains("active")) {
          if (item.style.maxHeight) {
            item.style.maxHeight = null;
          } else {
            item.style.maxHeight = item.scrollHeight + "px";
          }
        } else {
          item.style.maxHeight = null;
        }
      })
    })
  })


}

if (document.querySelector(".clientsSliderWrapper")) {
  let clientsSlider = new Swiper(".clientsSliderWrapper .swiper", {
    slidesPerView: 'auto',
    spaceBetween: 30,
    speed: 5000,
    autoplay: {
      enabled: true,
      // delay: 10,
      pauseOnMouseEnter: false,
      disableOnInteraction: true,
    },
  })
};





let smImgSlider = new Swiper(".smImgSlider", {
  loop: true,
  spaceBetween: 20,
  slidesPerView: 2,
  freeMode: true,
  watchSlidesProgress: true,
  loop: true,


  autoplay: {
    delay: 2500,
  },

  breakpoints: {
    767: {
      spaceBetween: 20,
    },

    1200: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },

});

let bigImgSlider = new Swiper(".bigImgSlider", {
  loop: true,
  spaceBetween: 10,

  thumbs: {
    swiper: smImgSlider,
  },
},
);


